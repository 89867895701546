import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import Layout from './Layout';
import bible from '../images/bible.jpg';
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';

export default function Videomenu() {
  return (
    <div>
    {/* <Layout/> */}
    <div className="heading text-center position-relative">
  <a className="back_btn text-decoration-none" href="/"><i class="fa fa-arrow-left"></i> Back</a>
 <h3>AFII Videos</h3>
 <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
 </div>
 <div className="banner_image">
              <div className="overlay">
     <div className="container">
        <div className="row">
   
            <div className="col-md-10 offset-md-1 mb-4">
            <div className="listes shadow mt-5 mb-3">
     <ListGroup className="navbar_header  listing_grp">
  <ListGroup.Item> <Nav.Link 
        href="/paul_torah"
         >Paul Torah Observant<i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="/hebrew_tanakh"
         >Hebrew Tanakh <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item> <Nav.Link 
        href="/moshiach"
         >Who Is Moshiach ? <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="/gehinnom"
         >Jewish Evangelism - Gehinnom <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/orthodox_bible"
         >Orthodox Jewish Bible <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/shipwreck"
         >Making A Shipwreck of Your Faith<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/zohar"
         >Zohar Three In One <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/tefillah"
         > Tefillah <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/messianic_bible"
         >Messianic Bible <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/rebbe"
         >Rebbe Not Messiah<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/ojb"
         >Rising Up Early With the OJB <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/hisgalus"
         >Hisgalus - Revelation <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/yehuda_jude"
         >Yehuda - Jude <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/yochanan_1"
         >Yochanan I - 1 John <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/yochanan_2"
         >Yochanan II - 2 John <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
  <Nav.Link 
        href="/yochanan_3"
         >Yochanan III - 3 John <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
  <Nav.Link 
        href="/kefa_1"
         >Kefa I - 1 Peter <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
  <Nav.Link 
        href="/kefa_2"
         >Kefa II - 2 Peter <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
  <Nav.Link 
        href="/philemon"
         >Philemon <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  
</ListGroup>
</div>
</div>
</div>
</div>
</div>
</div>
    
    <Footer/>
</div>
  )
}
