
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from "./components/Homepage";
import Hebrewbible from "./components/Hebrewbible";
import Yidishbible from "./components/Yidishbible";
import Index from "./components/Index";
import Digitalmenu from "./components/Digitalmenu";
import Yidish_bible from "./components/Yidish_bible";
import Digital_pdf from "./components/Digital_pdf";
import Hebrew_orthodox from "./components/Hebrew_orthodox";
import One_orthodox from "./components/One_orthodox";
import Rabbie from "./components/Rabbie";
import Yeshua_rabbie from "./components/Yeshua_rabbie";
import Testament from "./components/Testament";
import Complete_book from "./components/Complete_book";
import Creations from "./components/Creations";
import Messianic from "./components/Messianic";
import Grow_bible from "./components/Grow_bible";
import Videomenu from "./components/Videomenu";
import Pauh_torah from "./components/Pauh_torah";
import Hebrew_tanakh from "./components/Hebrew_tanakh";
import Moshiach from "./components/Moshiach";
import Gehinnom from "./components/Gehinnom";
import Gehinnom_1 from "./components/Gehinnom_1";
import Gehinnom_2 from "./components/Gehinnom_2";
import Gehinnom_3 from "./components/Gehinnom_3";
import Gehinnom_4 from "./components/Gehinnom_4";
import Orthodox_bible from "./components/Orthodox_bible";
import Shipwreck from "./components/Shipwreck";
import Zohar from "./components/Zohar";
import Tefillah from "./components/Tefillah";
import Messianic_bible from "./components/Messianic_bible";
import Messianic_1 from "./components/Messianic_1";
import Messianic_2 from "./components/Messianic_2";
import Messianic_3 from "./components/Messianic_3";
import Rebbe from "./components/Rebbe";
import Ojb from "./components/Ojb";
import Hisgalus from "./components/Hisgalus";
import Yehuda_jude from "./components/Yehuda_jude";
import Yochanan_1 from "./components/Yochanan_1";
import Yochanan_2 from "./components/Yochanan_2";
import Yochanan_3 from "./components/Yochanan_3";
import Kefa_1 from "./components/Kefa_1";
import Kefa_2 from "./components/Kefa_2";
import Philemon from "./components/Philemon";
import Subscribe from "./components/Subscribe";
import Website from "./components/Website";
import Contact from "./components/Contact";
import Information from "./components/Information";
import Afiimusic from "./components/Afiimusic";
import Music_1 from "./components/Music_1";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Bible_menu from "./components/Bible_menu";
import Rabbi_movie from "./components/Rabbi_movie";
import Bible_book from "./components/Bible_book";
import YidishBrit from "./components/YidishBrit";
import BibleRedescovered from "./components/BibleRedescovered";




function App() {
  return (
    <div className="App">
      
        <Routes>     
          {/* <Route path="/" element={<Layout/>}></Route> */}
          <Route path="/homepage" element={<Homepage/>}></Route>
          <Route path="/yidish-bible" element={<Yidish_bible/>}></Route>
          <Route path="/hebrewbible" element={<Hebrewbible/>}></Route>
          <Route path="/yidishbible" element={<Yidishbible/>}></Route>
          <Route path="/digital-menu" element={<Digitalmenu/>}></Route>
          <Route path="/digital-pdf" element={<Digital_pdf/>}></Route>
          <Route path="/hebrew-orthodox" element={<Hebrew_orthodox/>}></Route>
          <Route path="/one_orthodox" element={<One_orthodox/>}></Route>
          <Route path="/rabbie" element={<Rabbie/>}></Route>
          <Route path="/yeshua_rabbie" element={<Yeshua_rabbie/>}></Route>
          <Route path="/testament" element={<Testament/>}></Route>
          <Route path="/complete_book" element={<Complete_book/>}></Route>
          <Route path="/creation" element={<Creations/>}></Route>
          <Route path="/messianic" element={<Messianic/>}></Route>
          <Route path="/grow_bible" element={<Grow_bible/>}></Route>
          <Route path="/videomenu" element={<Videomenu/>}></Route>
          <Route path="/hebrew_tanakh" element={<Hebrew_tanakh/>}></Route>
          <Route path="/paul_torah" element={<Pauh_torah/>}></Route>
          <Route path="/moshiach" element={<Moshiach/>}></Route>
          <Route path="/gehinnom" element={<Gehinnom/>}></Route>
          <Route path="/gehinnom_4" element={<Gehinnom_4/>}></Route>
          <Route path="/gehinnom_3" element={<Gehinnom_3/>}></Route>
          <Route path="/gehinnom_2" element={<Gehinnom_2/>}></Route>
          <Route path="/gehinnom_1" element={<Gehinnom_1/>}></Route>
          <Route path="/orthodox_bible" element={<Orthodox_bible/>}></Route>
          <Route path="/shipwreck" element={<Shipwreck/>}></Route>
          <Route path="/zohar" element={<Zohar/>}></Route>
          <Route path="/tefillah" element={<Tefillah/>}></Route>
          <Route path="/messianic_bible" element={<Messianic_bible/>}></Route>
          <Route path="/messianic_1" element={<Messianic_1/>}></Route>
          <Route path="/messianic_2" element={<Messianic_2/>}></Route>
          <Route path="/messianic_3" element={<Messianic_3/>}></Route>
          <Route path="/rebbe" element={<Rebbe/>}></Route>
          <Route path="/ojb" element={<Ojb/>}></Route>
          <Route path="/hisgalus" element={<Hisgalus/>}></Route>
          <Route path="/yehuda_jude" element={<Yehuda_jude/>}></Route>
          <Route path="/yochanan_1" element={<Yochanan_1/>}></Route>
          <Route path="/yochanan_2" element={<Yochanan_2/>}></Route>
          <Route path="/yochanan_3" element={<Yochanan_3/>}></Route>
          <Route path="/kefa_1" element={<Kefa_1/>}></Route>
          <Route path="/kefa_2" element={<Kefa_2/>}></Route>
          <Route path="/philemon" element={<Philemon/>}></Route>
          <Route path="/subscribe" element={<Subscribe/>}></Route>
          <Route path="/website" element={<Website/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/information" element={<Information/>}></Route>
          <Route path="/afii_music" element={<Afiimusic/>}></Route>
          <Route path="/music_1" element={<Music_1/>}></Route>
          <Route path="/priavcy" element={<Privacy/>}></Route>
          <Route path="/terms" element={<Terms/>}></Route>
          <Route path="/bible_menu" element={<Bible_menu/>}></Route>
          <Route path="/rabbie_movie" element={<Rabbi_movie/>}></Route>
          <Route path="/bible_book" element={<Bible_book/>}></Route>
          <Route path="/yidish_brit" element={<YidishBrit/>}></Route>
          <Route path="/bible_rediscovered" element={<BibleRedescovered/>}></Route>
          <Route path="/" element={<Index/>}></Route>
          
          
        </Routes>
    
    </div>
  );
}

export default App;