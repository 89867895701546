import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Footer from './Footer';
import { NavLink } from 'react-bootstrap';
import img_1 from '../images/img_1.jpg';
import img_2 from '../images/img_2.jpg';
import img_3 from '../images/img_3.jpg';
import img_4 from '../images/img_4.jpg';
import white from '../images/white.png';

export default function Bible_book(props) {
    const [loader,setLoader] = useState(false);

 
    const navigate = useNavigate();
    useEffect(()=>{
  
      setTimeout(() => {
        setLoader(true);
      }, 1500);
    },[navigate])
  return (
    <div>
    {loader? <>     <div className="body">
<div className="heading text-center position-relative">
<button
   className="back_btn"
   onClick={() => navigate(-1)}>
   <i class="fa fa-arrow-left"></i> Back
</button>
<h3>ebooks</h3>
<a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
</div>
<div className="banner_images">
         <div className="overlay ">
         <div className="container">
             <div className="row">
             <div className="col-md-8 offset-md-2">
                <div className="infromation">
                <NavLink href="https://play.google.com/store/books/details/Dr_Phillip_Goble_The_Orthodox_Jewish_Bible?id=7Bg8CoRcI3AC">
               <div className="first_book">
                <img src={img_1} />
                <div>
                    <p>The Orthodox Jewish Bible</p>
                    <p>Dr. Philip Goble</p>
                    <p>Jan 2002. AFI International Publishers</p>
                </div>
               </div>
               </NavLink>

               <NavLink href="https://play.google.com/store/books/details/Dr_Phillip_Goble_The_Orthodox_Jewish_Brit_Chadasha?id=ItbZCgAAQBAJ">
               <div className="first_book">
                <img src={img_2} />
                <div>
                    <p>The Orthodox Jewish Brit Chadasha</p>
                    <p>Dr. Philip Goble</p>
                    <p>Jan 1997 · AFI International Publishers</p>
                </div>
               </div>
               </NavLink>

               <NavLink href="https://play.google.com/store/books/details/Dr_Phillip_Goble_New_Creation_Bible_for_Muslims?id=gBhyDgAAQBAJ">
               <div className="first_book">
                <img src={img_3} />
                <div>
                    <p>New Creation Bible for Muslims</p>
                    <p>Dr. Philip Goble</p>
                    <p>Mar 2017 · AFI International Publishers</p>
                </div>
               </div>
               </NavLink>

               <NavLink href="https://play.google.com/store/books/details/Dr_Phillip_Goble_The_One_Year_Orthodox_Jewish_Bibl?id=VOM-BAAAQBAJ">
               <div className="first_book">
                <img src={img_4} />
                <div>
                    <p>The One Year Orthodox Jewish Bible</p>
                    <p>Dr. Philip Goble</p>
                    <p>Aug 2014 · Artists for Israel International</p>
                </div>
               </div>
               </NavLink>

               <NavLink href="https://play.google.com/store/books/details/Dr_Phillip_Goble_The_Hebrew_Orthodox_Jewish_Bible?id=L-M-BAAAQBAJ	">
               <div className="first_book">
                <img src={img_4} />
                <div>
                    <p>The Hebrew Orthodox Jewish Bible</p>
                    <p>Dr. Phillip Goble</p>
                    <p>Aug 2014 · Artists For Israel International		</p>
                </div>
               </div>
               </NavLink>

               <NavLink href="https://play.google.com/store/books/details/Dr_Phillip_Goble_The_One_Year_Orthodox_Jewish_Bibl?id=VOM-BAAAQBAJ">
               <div className="first_book">
                <img src={white} />
                <div>
                    <p>The Orthodox Hasidic Yiddish Bible</p>
                    <p>Dr. Phillip E. Goble</p>
                    <p>https://play.google.com/store/books/details/Dr_Phillip_E_Goble_The_Orthodox_Hasidic_Yiddish_Bi?id=TXRfDAAAQBAJ</p>
                </div>
               </div>
               </NavLink>

               <NavLink href="https://www.amazon.com/Phillip-E-Goble/e/B001KCF944/ref=dp_byline_cont_pop_book_1">
               <div className="first_book">
                <img src={img_4} />
                <div>
                    <p>To receive the paperback edition:</p>
                    <p>Send $30 check or money order payable to:</p>
                    <p>AFII <br/>PO Box 2056<br/>NY, NY 10163</p>
                </div>
               </div>
               </NavLink>
               </div>
               </div>
               </div>
               </div>
           </div>
           </div>
</div></> : <><div className="loadr_spin">
   <Spinner animation="grow" variant="primary" />
   </div></> }

<Footer/>
</div>
  )
}
