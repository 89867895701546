import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import Iframe from 'react-iframe';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas'
import Homepage from './Homepage';
import logo from '../images/logo.jpg';
import { Link } from 'react-router-dom';



export default function Layout() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
        <Navbar bg="white" className="shadows p-0" expand="lg">
      <Container> 
        <Navbar.Brand href="/" className="w-100"> <img src={logo} /> </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />

        <Navbar.Collapse id="basic-navbar-nav">
        <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>AFII</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Nav className="me-auto">
            <Link 
            to="/homepage"
             >Orthodox Jewish Bible</Link>
             <Link 
            to="/hebrewbible"
             target="main">Orthodox Hebrew Bible</Link>
             <Link 
            to="/hebrewbible"
             target="main">Orthodox Yiddish Bible</Link>
            {/* <Nav.Link 
            onClick={() => setUrl("https://biblehub.com/ojhb/")}
            >Orthodox Hebrew Bible</Nav.Link>
            <Nav.Link 
            onClick={() => setUrl("https://biblehub.com/ohyb/	")}
            >Orthodox Yidish Bible</Nav.Link> */}

          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
     
        </Navbar.Collapse>
      </Container>
    </Navbar>
  
 
{/* <div>


<iframe
                     width="100%"
                     height="100%"
                    src={url} 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                    ></iframe>
   </div> */}

    </div>
  )
}
