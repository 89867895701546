import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import instagram from '../images/instagram.png';
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';
import { NavLink } from 'react-bootstrap';

export default function Footer() {
  return (
    <div>
        <div className="footer">
            <div className="container-fluid">
               <div className="row">
                   <div className="col-md-12">
                    <div className="main_footer">
                      <p className="mb-0">Copyright 2022 AFII. All rights reserved</p>
                      <div className="links_footer">
                      <span><NavLink href="/priavcy" > Privacy Policy</NavLink></span>
                        <span><NavLink href="/terms" >Terms & Conditions</NavLink></span>
                      </div>
                      <div className="social_icons_pic">
                      <img src={instagram}/>
                      <NavLink href="https://www.facebook.com/AFIIORG">  <img src={facebook}/></NavLink>
                      <NavLink href="https://www.youtube.com/c/PhillipGoble"> <img src={youtube}/></NavLink>
                      </div>
                      </div>
                   </div>
               </div>
            </div>
        </div>
    </div>  
  )
}
