import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { NavLink,Nav,ListGroup } from 'react-bootstrap';

export default function Afiimusic(props) {
    const navigate = useNavigate();
  return (
    <div>
                <div>
    <div className="heading text-center position-relative">
    <button
        className="back_btn"
        onClick={() => navigate(-1)}>
        <i class="fa fa-arrow-left"></i> Back
    </button>
    <h3>AFII Music</h3>
    <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
    </div>
    <div className="banner_image">
              <div className="overlay">
              <div className="container">
                  <div className="row">
                  <div className="col-md-10 offset-md-1 mb-4">
            <div className="listes shadow mt-5 mb-3">
     <ListGroup className="navbar_header  listing_grp">
  <ListGroup.Item> <Nav.Link 
        href="https://www.afii.org/songs/sprinkle.mp3"
         >Come Sprinkle Me<i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="https://www.afii.org/songs/healing.mp3"
         >Healing Song <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item> <Nav.Link 
        href="https://www.afii.org/songs/shallnot.mp3"
         >I Shall Not Die <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="https://www.afii.org/songs/moshiach.mp3"
         >Moshiach,Moshiach <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/never.mp3"
         >Nevertheless, the L-rd Stood by Me <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/summer.mp3"
         >Summer is Ended<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/thisone.mp3"
         >This One and That One <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/leadme.mp3"
         >Chorale-Lead Me to the Rock <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/fire.mp3"
         >I am with You Through the Fire <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/kaddish.mp3"
         >Kaddish<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/notall.mp3"
         >Not All Issacs are Returned <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/tribes.mp3"
         >Song for the Tribes <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  {/* <ListGroup.Item>
  <Nav.Link 
        href="/music_1"
         >Summer is Ended <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item> */}

  <ListGroup.Item>
  <Nav.Link 
        href="https://www.afii.org/songs/rebbe.mp3"
         >The Brooklyn Rebbe From Avenue J <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

</ListGroup>
{/* <div className="back_page">
                    <h2>Back to the <span>Books and Materials Page</span></h2>
                   </div> */}
</div>
</div>

                  {/* <div className="col-md-8 offset-md-2">
                   <div className="afii_music">
                    <div className="side_one">
                   <h3>Side One</h3>
                   <NavLink href="/music_1">  <ul>
                    <li>Come Sprinkle Me</li>
                    <li>Healing Song</li>
                    <li>I Shall Not Die</li>
                    <li>Moshiach,Moshiach</li>
                    <li>Nevertheless, the L-rd Stood by Me </li>
                    <li>Summer is Ended</li>
                    <li>This One and That One</li>
                   </ul></NavLink>
                   </div>
                   <div className="side_two">
                   <h3>Side Two</h3>
                  <NavLink href="/music_1"> <ul>
                    <li>Chorale-Lead Me to the Rock</li>
                    <li>I am with You Through the Fire</li>
                    <li>Kaddish</li>
                    <li>Not All Issacs are Returned</li>
                    <li>Song for the Triber </li>
                    <li>Summer is Ended</li>
                    <li>The Brooklyn Rebbe From Avenue J</li>
                   </ul></NavLink>
                   </div>
                   <div className="back_page">
                    <h2>Back to the <span>Books and Materials Page</span></h2>
                   </div>
                    </div>
                    </div> */}
                    </div>
                    </div>
                </div>
                </div>
    </div>
    <Footer/>
    </div>
  )
}
