import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import Layout from './Layout';
import bible from '../images/bible.jpg';
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';

export default function Bible_menu() {
  return (
    <div>
        <div className="body">
          <div className="heading text-center position-relative">
  <a className="back_btn text-decoration-none" href="/"><i class="fa fa-arrow-left"></i> Back</a>
 <h3>Bibles Menu</h3>
 <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
 </div>
 <div className="banner_img">
              <div className="overlay">
     <div className="container">
        <div className="row">
   
            <div className="col-md-10 offset-md-1 mb-4">
            <div className="listes shadow mt-5 mb-3">
     <ListGroup className="navbar_header  listing_grp">

     <ListGroup.Item> <Nav.Link 
        href="/bible_rediscovered"
         >Bibles Rediscovered<i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>

     <ListGroup.Item> <Nav.Link 
        href="/bible_book"
         > Bibles <i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item> <Nav.Link 
        href="https://www.amazon.com/Phillip-E-Goble/e/B001KCF944/ref=dp_byline_cont_pop_book_1"
         >Books on Amazon: <i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="https://play.google.com/store/search?q=%22Phillip+Goble%22&amp;c=books&c=apps"
         >Books on Google Play <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  
 
</ListGroup>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
    <Footer/>
    </div>
  )
}
