import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Spinner from 'react-bootstrap/Spinner';

export default function Rabbi_movie(props) {
    const [loader,setLoader] = useState(false);

 
    const navigate = useNavigate();
    useEffect(()=>{
  
      setTimeout(() => {
        setLoader(true);
      }, 1500);
    },[navigate])
  return (
    <div>
    {loader? <>    <div className="body">
   <div className="heading text-center position-relative">
   <button
       className="back_btn"
       onClick={() => navigate(-1)}>
       <i class="fa fa-arrow-left"></i> Back
   </button>
<h3>Rabbi From Tarsus (Full Movie)</h3>
<a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
</div>

<iframe
                width="100%"
                height="100%"
               src="https://www.youtube.com/embed/24p95vn8gJI" 
               name="main"
               frameborder="0" 
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
               allowfullscreen
               id="myId"
               className="myClassname"
               display="initial"
               position="relative"
               ></iframe>
</div></> : <><div className="loadr_spin">
   <Spinner animation="grow" variant="primary" />
   </div></> }

<Footer/>
</div>
  )
}
