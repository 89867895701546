import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Footer from './Footer';
import { NavLink } from 'react-bootstrap';

export default function Information(props) {
    const [loader,setLoader] = useState(false);

 
    const navigate = useNavigate();
    useEffect(()=>{
  
      setTimeout(() => {
        setLoader(true);
      }, 1500);
    },[navigate])

  return (
    <div>
         {loader? <>     <div className="body">
    <div className="heading text-center position-relative">
    <button
        className="back_btn"
        onClick={() => navigate(-1)}>
        <i class="fa fa-arrow-left"></i> Back
    </button>
    <h3>Information</h3>
    <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
    </div>
    <div className="banner_img">
              <div className="overlay">
              <div className="container">
                  <div className="row">
                  <div className="col-md-8 offset-md-2">
                   <div className="infromation">
                    <p>
                        The AFII app is designed to consolidate the most often used iPhone compatible options from the www.afii.org website. The Digital Library item "Is the NT Trustworthy?" is a PDF file which can take up to 2 minutes to load, please be patient. User can then save and open this FREE in iBooks option after loading. Blue items are selectable and will take you to multiple online OJB references to study. Whenever redirected to the mobile AFII.ORG website, press BIBLES option whether for books or Bibles. The contact and donate options will redirect user to external websites.

                    </p>
                    <p>AFII is a registered Non-Profit organization and has out of app options for purchase of Bibles, digital and print, as well as a secured PayPal gift donation option. Please use contact information option to connect to AFII organization for AFII related or website content questions.</p>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
    </div></> : <><div className="loadr_spin">
        <Spinner animation="grow" variant="primary" />
        </div></> }
   
    <Footer/>
    </div>
  )
}
