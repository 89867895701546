import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Footer from './Footer';
import { NavLink } from 'react-bootstrap';


export default function Privacy() {
    const [loader,setLoader] = useState(false);

 
    const navigate = useNavigate();
    useEffect(()=>{
  
      setTimeout(() => {
        setLoader(true);
      }, 1500);
    },[navigate])
  return (
    <div>
    {loader? <>     <div className="body bodies">
<div className="heading text-center position-relative">
<button
   className="back_btn"
   onClick={() => navigate(-1)}>
   <i class="fa fa-arrow-left"></i> Back
</button>
<h3>Privacy Policy</h3>
<a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
</div>
<div className="banner_img">
         <div className="overlay">
         <div className="container">
             <div className="row">
             <div className="col-md-8 offset-md-2">
              <div className="infromation">
               <p>
               Artists for Israel International Inc., (AFII), is committed to keeping any and all personal information
collected of those individuals that visit our website and/or web app and or web app and make use of our
online facilities and services accurate, confidential, secure and private. Our privacy policy has been
designed and created to ensure those affiliated with of our commitment and realization of our
obligation not only to meet but to exceed most existing privacy standards. THEREFORE, this Privacy
Policy Agreement shall apply to AFII, and thus it shall govern any and all data collection and usage
thereof. Through the use of www.afii.org you are herein consenting to the following data procedures
expressed within this agreement. Collection of Information It is highly recommended and suggested that
you review the privacy policies and statements of any website and/or web app you choose to use or
frequent as a means to better understand the way in which other website and/or web apps garner,
make use of and share information collected. Use of Information Collected AFII may collect and may
make use of personal information to assist in the operation of our website and/or web app and to
ensure delivery of the services you need and request. At times, we may find it necessary to use
personally identifiable information as a means to keep you informed of other possible products and/or
services that may be available to you from www.afii.org. AFII may also be in contact with you with
regards to completing surveys and/or research questionnaires related to your opinion of current or
potential future services that may be offered. AFII does not now, nor will it in the future, sell, rent or
lease any of our customer lists and/or names to any third parties. AFII may disclose your personal
information, without prior notice to you, only if required to do so in accordance with applicable laws
and/or in a good faith belief that such action is deemed necessary or is required in an effort to: •Remain
in conformance with any decrees, laws and/or statutes or in an effort to comply with any process which
may be served upon AFII and/or our website and/or web app. •Maintain, safeguard and/or preserve all
the rights and/or property of AFII; and •Perform under demanding conditions in an effort to safeguard
the personal safety of users of www.afii.org and/or the general public. Children Under Age of 13: AFII
does not knowingly collect personal identifiable information from children under the age of thirteen (13)
without verifiable parental consent. If it is determined that such information has been inadvertently
collected on anyone under the age of thirteen (13), we shall immediately take the necessary steps to
ensure that such information is deleted from our system&#39;s database. Anyone under the age of thirteen
(13) must seek and obtain parent or guardian permission to use this website and/or web app.
Unsubscribe or Opt-Out All users and/or visitors to our website and/or web app have the option to
discontinue receiving communication from us and/or reserve the right to discontinue receiving
communications by way of email or newsletters. To discontinue or unsubscribe to our website and/or
web app please send an email that you wish to unsubscribe to office@afii.org. If you wish to
unsubscribe or opt-out from any third party website and/or web apps, you must go to that specific
website and/or web app to unsubscribe and/or opt-out. Links to Other Web Sites Our website and/or
web app does contain links to affiliate and other website and/or web apps. AFII does not claim nor
accept responsibility for any privacy policies, practices and/or procedures of other such website and/or
web apps. Therefore, we encourage all users and visitors to be aware when they leave our website
and/or web app and to read the privacy statements of each and every website and/or web app that
collects personally identifiable information. The aforementioned Privacy Policy Agreement applies only
and solely to the information collected by our website and/or web app. Security AFII shall endeavor and shall take every precaution to maintain adequate physical, procedural and technical security with
respect to our offices and information storage facilities so as to prevent any loss, misuse, unauthorized
access, disclosure or modification of the user&#39;s personal information under our control. Changes to
Privacy Policy Agreement AFII reserves the right to update and/or change the terms of our privacy
policy, and as such we will post those change to our website and/or web app homepage at www.afii.org,
so that our users and/or visitors are always aware of the type of information we collect, how it will be
used, and under what circumstances, if any, we may disclose such information. If at any point in time
AFII decides to make use of any personally identifiable information on file, in a manner vastly different
from that which was stated when this information was initially collected, the user or users shall be
promptly notified by email. Users at that time shall have the option as to whether or not to permit the
use of their information in this separate manner. Acceptance of Terms Through the use of this website
and/or web app, you are hereby accepting the terms and conditions stipulated within the
aforementioned Privacy Policy Agreement. If you are not in agreement with our terms and conditions,
then you should refrain from further use of our sites. In addition, your continued use of our website
and/or web app following the posting of any updates or changes to our terms and conditions shall mean
that you are in agreement and acceptance of such changes. How to Contact Us If you have any questions
or concerns regarding the Privacy Policy Agreement related to our website and/or web app, please feel
free to contact us at:<span><NavLink href="https://www.phillipgoble.com/contact.html"> https://www.phillipgoble.com/contact.html </NavLink></span>

               </p>
              
               </div>
               </div>
               </div>
               </div>
           </div>
           </div>
</div></> : <><div className="loadr_spin">
   <Spinner animation="grow" variant="primary" />
   </div></> }

<Footer/>
</div>
  )
}
