import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Footer from './Footer';
import { NavLink } from 'react-bootstrap';

export default function Terms() {
    const [loader,setLoader] = useState(false);

 
    const navigate = useNavigate();
    useEffect(()=>{
  
      setTimeout(() => {
        setLoader(true);
      }, 1500);
    },[navigate])
  return (
    <div>
    {loader? <>     <div className="body ">
<div className="heading text-center position-relative">
<button
   className="back_btn"
   onClick={() => navigate(-1)}>
   <i class="fa fa-arrow-left"></i> Back
</button>
<h3>Terms & Conditions</h3>
<a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
</div>
<div className="banner_img">
         <div className="overlay">
         <div className="container">
             <div className="row">
             <div className="col-md-8 offset-md-2">
              <div className="infromation">
               <p>
               Acceptance of Terms: Through your use of this website and/or web app, you are hereby accepting the
terms and conditions stipulated within the aforementioned Privacy Policy Agreement posted on this
web app for AFII. If you are not in agreement with our terms and conditions, then you should refrain
from further use of our sites. In addition, your continued use of our website and/or web app following
the posting of any updates or changes to our terms and conditions shall mean that you are in agreement
and acceptance of such changes. How to Contact Us If you have any questions or concerns regarding the
Privacy Policy Agreement related to our website and/or web app, please feel free to contact us at:
<span><NavLink href="https://www.phillipgoble.com/contact.html">https://www.phillipgoble.com/contact.html.</NavLink></span>

               </p>
              
               </div>
               </div>
               </div>
               </div>
           </div>
           </div>
</div></> : <><div className="loadr_spin">
   <Spinner animation="grow" variant="primary" />
   </div></> }

<Footer/>
</div>
  )
}
