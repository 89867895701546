import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Layout from './Layout';
import bible from '../images/bible.jpg';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function Messianic_bible(props) {
  const [loader,setLoader] = useState(false);

 
  const navigate = useNavigate();
  useEffect(()=>{

    setTimeout(() => {
      setLoader(true);
    }, 1500);
  },[navigate])
  return (
    <div>
         {/* <Layout/> */}
         {loader? <>   <div className="body">
    <div className="heading text-center position-relative">
    <button
        className="back_btn"
        onClick={() => navigate(-1)}>
        <i class="fa fa-arrow-left"></i> Back
    </button>
 <h3>Messianic Bible</h3>
 <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
 </div>
 <div className="banner_img">
              <div className="overlay">
     <div className="container">
        <div className="row">
   
            <div className="col-md-10 offset-md-1 mb-4">
            <div className="listes shadow mt-5 mb-3">
     <ListGroup className="navbar_header  listing_grp">
  <ListGroup.Item> <Nav.Link 
        href="/messianic_1"
         >https://www.youtube.com/watch?v=7-FmFDWKohU<i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="/messianic_2"
         >https://www.youtube.com/watch?v=5YW_VKZ6b4Q <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item> <Nav.Link 
        href="/messianic_3"
         >https://www.youtube.com/watch?v=ei5rIp7xtuw <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
         </ListGroup>
 
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    <Footer/>
    </div></> : <><div className="loadr_spin">
        <Spinner animation="grow" variant="primary" />
        </div></> }
      
    </div>
  )
}
