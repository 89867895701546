import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import Layout from './Layout';
import bible from '../images/bible.jpg';
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';

export default function Index() {
  return (
    <div className="body">
        <Layout/>
        <div className="banner_img">
              <div className="overlay">
         <div className="container">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="listes shadow mt-5">
         <ListGroup className="navbar_header ">
      <ListGroup.Item> <Nav.Link 
            href="/homepage"
             >Orthodox Jewish Bible <i class="fa fa-chevron-right"></i>
             </Nav.Link></ListGroup.Item>
      <ListGroup.Item><Nav.Link 
            href="/hebrewbible"
             >Orthodox Hebrew Bible <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
      <ListGroup.Item> <Nav.Link 
            href="/yidishbible"
             >Orthodox Yiddish Bible <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
      <ListGroup.Item><Nav.Link 
            href="/digital-menu"
             >Digital Library <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="/videomenu"
             >Videos <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="/bible_menu"
             >Bibles <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="/afii_music"
             >AFII Music <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="https://www.youtube.com/user/okay1010?reload=9"
             >Subscribe to AFII Youtube Channel <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="/contact"
             >Donations & Contact Us <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="/website"
             >Website <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Nav.Link 
            href="/information"
             >Information <i class="fa fa-chevron-right"></i></Nav.Link>
      </ListGroup.Item>
    </ListGroup>
    </div>
    </div>
    </div>
         </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}
