import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import Layout from './Layout';
import bible from '../images/bible.jpg';
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';
import { ListGroupItem } from 'react-bootstrap';

export default function Digitalmenu() {
  return (
    <div>
    {/* <Layout/> */}
    <div className="heading text-center position-relative">
  <a className="back_btn text-decoration-none" href="/"><i class="fa fa-arrow-left"></i> Back</a>
 <h3>AFII Digital Library</h3>
 <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
 </div>
 <div className="banner_img">
              <div className="overlay">
     <div className="container">
        <div className="row">
   
            <div className="col-md-10 offset-md-1 mb-4">
            <div className="listes shadow mt-5 mb-3">
     <ListGroup className="navbar_header  listing_grp">
  <ListGroup.Item> <Nav.Link 
        href="/digital-pdf"
         >The Orthodox Jewish Bible ISBN 978-0-939341-05-4 @ 2011<i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="https://yiddishbible.net" target="_blank"
         >The Yiddish Orthodox Jewish Bible HaDerech HaMeshichist Yid @ 2011 <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item> <Nav.Link 
        href="/hebrew-orthodox"
         >The Hebrew Orthodox Jewish Bible Derech Hashem Elodeichem @ 2011 <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="/one_orthodox"
         >The One Year Orthodox Jewish Bible  @ 2014 <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/rabbie"
         >Rabbi of Tarsus Script ISBN 0-8423-5124-8 @1981 <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/yeshua_rabbie"
         >How to Point to Yeshua in Your Rabbi's  ISBN 0-939341-009 @ 1986<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/testament"
         >Is the New Testament Trustworthy? @2003 <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/complete_book"
         >Complete Book For Artists For Israel ISBN 0-939341-020-6 @ 1989 <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/creation"
         >The New Creations Book For Muslims  ISBN 978-0939-341-061 @2011 <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>
  <ListGroup.Item>
  <Nav.Link 
        href="/messianic"
         >How to Grow a Messianic Yeshiva  ISBN 978-0878081-073 @ 1981<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>


  <ListGroup.Item>
  <Nav.Link 
        href="/grow_bible"
         >Everything You Need To Know To Grow ISBN 0-87808-421-5 @ 9175 <i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
  <Nav.Link 
        href="/rabbie_movie"
         >Rabbi From Tarsus (Full Movie)<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

  <ListGroup.Item>
       <Nav.Link href="/yidish_brit">Orthodox Yiddish Brit Chadasha (New Testament)Updated Aaron Krelenbaum 1946<i class="fa fa-chevron-right"></i></Nav.Link>
  </ListGroup.Item>

</ListGroup>
</div>
</div>
</div>
</div>
</div>
</div>
    
    <Footer/>
</div>
  )
}
