import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.jpg';
import visa from '../images/visa.png';
import paypal from '../images/paypal.png';
import ms from '../images/ms.png';
import Footer from './Footer';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from 'react-bootstrap';

export default function Contact(props) {
  const [loader,setLoader] = useState(false);

 
  const navigate = useNavigate();
  useEffect(()=>{

    setTimeout(() => {
      setLoader(true);
    }, 1500);
  },[navigate])
  return (
    <div>
      {loader? <>  
            <div className="body">
    <div className="heading text-center position-relative">
    <button
        className="back_btn"
        onClick={() => navigate(-1)}>
        <i class="fa fa-arrow-left"></i> Back
    </button>
    <h3>Contact Information</h3>
    <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
    </div>
    <div className="banner_img">
              <div className="overlay">
                <div className="container">
                  <div className="row">
                  <div className="col-md-8 offset-md-2">
                   <div className="contact_us">
                    <h3>Artists for Israel International P.O. Box 2056 New York, New York 10163 U.S.A<span>212-245-4188</span> </h3>
                    <h4> office@afii.org</h4>
                    
                    <div className="text-center emails">
                        <div>
                    <NavLink href="https://www.phillipgoble.com/contact.html"><button className="conatct_us">Contact Us via Email</button></NavLink>
                    </div>
                    <img src={logo} />
                    
                    </div>

                    <div className="text-center">
                       <NavLink href="https://www.paypal.com/paypalme/artistsforisrael"><button className="btn_donate">Donate</button></NavLink> 
                    </div>
                    <div className="card_payment text-center mt-3">
                    <img src= {visa} />
                     <img src={ms} />
                     <img src={paypal} className="paypal"/>
                    </div>

                   </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
    </div></> : <><div className="loadr_spin">
        <Spinner animation="grow" variant="primary" />
        </div></> }

    <Footer/>
    </div>
  )
}
