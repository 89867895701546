import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

import Layout from './Layout';
import bible from '../images/bible.jpg';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

export default function Gehinnom(props) {
    const navigate = useNavigate();
  return (
    <div>
         {/* <Layout/> */}
         <div className="body">
    <div className="heading text-center position-relative">
    <button
        className="back_btn"
        onClick={() => navigate(-1)}>
        <i class="fa fa-arrow-left"></i> Back
    </button>
 <h3>Jewesh Evangelism - Gehinnom</h3>
 <a className="home_btn text-decoration-none" href="/"><i class="fa fa-home"></i></a>
 </div>
 <div className="banner_img">
              <div className="overlay">
     <div className="container">
        <div className="row">
   
            <div className="col-md-10 offset-md-1 mb-4">
            <div className="listes shadow mt-5 mb-3">
     <ListGroup className="navbar_header  listing_grp">
  <ListGroup.Item> <Nav.Link 
        href="/gehinnom_1"
         >https://www.youtube.com/watch?v=AwEONUSyUwU<i class="fa fa-chevron-right"></i>
         </Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="/gehinnom_2"
         >https://www.youtube.com/watch?v=4h-MtY0FQRw <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item> <Nav.Link 
        href="/gehinnom_3"
         >https://www.youtube.com/watch?v=xyNmXqeG6Ak <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>
  <ListGroup.Item><Nav.Link 
        href="/gehinnom_4"
         >https://www.youtube.com/watch?v=hb3PLE1n7Cg <i class="fa fa-chevron-right"></i></Nav.Link></ListGroup.Item>

    </ListGroup>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <Footer/>
    </div>
  )
}
